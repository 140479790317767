<template>
  <div class="w-full">
    <VText
      v-if="isText(field)"
      :model-value="value"
      :placeholder="$t('app.search')"
      :readonly="readonly"
      @update:model-value="onUpdateCriterionValue"
      @keyup.enter="onKeyupEnter"
    />

    <VColor
      v-else-if="isColor(field)"
      :model-value="value"
      :placeholder="$t('app.search')"
      :readonly="readonly"
      :is-input-readonly="false"
      @update:model-value="onUpdateCriterionValue"
      @keyup.enter="onKeyupEnter"
    />

    <VText
      v-else-if="isNumber(field)"
      :model-value="value"
      :placeholder="$t('app.search')"
      type="number"
      :readonly="readonly"
      @update:model-value="onUpdateCriterionValue"
      @keyup.enter="onKeyupEnter"
    />

    <VDatePicker
      v-else-if="isDate(field)"
      :model-value="value"
      :readonly="readonly"
      :placeholder="$t('app.search')"
      @update:model-value="onUpdateCriterionValue"
      @keyup.enter="onKeyupEnter"
    />

    <VSelect
      v-else-if="isSelect(field)"
      :model-value="value"
      :placeholder="$t('app.search')"
      :options="options[field]"
      :readonly="readonly"
      multiple
      @update:model-value="onUpdateCriterionValue"
      @keyup.enter="onKeyupEnter"
    />

    <VTags
      v-else-if="isTags(field)"
      :model-value="value"
      :placeholder="$t('app.search')"
      :readonly="readonly"
      @update:model-value="onUpdateCriterionValue"
      @keyup.enter="onKeyupEnter"
    />

    <VText v-else disabled :placeholder="$t('app.search')" />
  </div>
</template>

<script>
// Composables
import useFilterForm from "@/composables/useFilterForm";
// Components
import VText from "@/components/inputs/VText";
import VColor from "@/components/inputs/VColor";
import VSelect from "@/components/inputs/VSelect";
import VTags from "@/components/inputs/VTags";
import VDatePicker from "@/components/inputs/VDatePicker";

export default {
  components: {
    VText,
    VColor,
    VSelect,
    VTags,
    VDatePicker
  },
  props: {
    index: {
      type: Number,
      default: 0
    },
    options: {
      type: Object,
      required: true
    },
    types: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const {
      isText,
      isColor,
      isSelect,
      isDate,
      isNumber,
      isTags,
      field,
      value,
      readonly,
      onUpdateCriterionValue,
      onKeyupEnter
    } = useFilterForm(props.index, emit, props.types);

    return {
      isText,
      isColor,
      isDate,
      isTags,
      isNumber,
      isSelect,
      field,
      value,
      readonly,
      onUpdateCriterionValue,
      onKeyupEnter
    };
  }
};
</script>
