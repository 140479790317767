<template>
  <div>
    <VCard>
      <template #title>
        {{ $t("app.advanced_search") }}
      </template>

      <template #title-actions>
        <VButton
          class="btn-outline-secondary hidden sm:flex"
          :label="$t('app.add_filter')"
          @click="onClickAddCriterion"
        />
      </template>

      <template #content>
        <div
          v-for="(criterion, index) in criteria"
          :key="index"
          class="grid grid-cols-5 gap-8 mb-4"
        >
          <div class="col-span-2">
            <VSelect
              :model-value="criterion.f"
              data-test="advanced-search-field"
              :options="fieldOptions"
              :readonly="criterion.fieldReadonly"
              class="w-full flex items-center"
              input-class="w-full"
              @update:model-value="onUpdateCriterionField($event, index)"
            >
              <template #label>
                <VAction
                  :icon="criterion.isPinned ? 'pin' : 'unpin'"
                  class="cursor-pointer mr-2"
                  :is-loading="criterion.isPinning"
                  :disabled="criterion.isLocked"
                  @click="onClickPin(criterion, index)"
                />

                <label class="mr-2">
                  {{ $t("app.field") }}
                </label>
              </template>
            </VSelect>
          </div>

          <div>
            <VSelect
              :model-value="criterion.c"
              :options="typeOptions"
              data-test="advanced-search-comparator"
              class="w-full flex items-center"
              input-class="w-full"
              :readonly="criterion.comparatorReadonly"
              @update:model-value="onUpdateCriterionComparator($event, index)"
            >
              <template #label>
                <label class="mr-2">
                  {{ $t("app.type") }}
                </label>
              </template>
            </VSelect>
          </div>

          <div class="col-span-2 w-full flex items-center">
            <label class="mr-2">
              {{ $t("app.value") }}
            </label>

            <slot name="content" :index="index" :filters="filters" />

            <div class="flex ml-2">
              <VAction
                :icon="criterion.isLocked ? 'lock' : 'unlock'"
                class="cursor-pointer"
                :is-loading="criterion.isLocking"
                @click="onClickLock(criterion, index)"
              />

              <VAction
                icon="delete"
                color="text-theme-6"
                @click="onClickRemoveCriterion(index)"
              />
            </div>
          </div>
        </div>
      </template>

      <template #actions>
        <div class="flex justify-between">
          <div class="flex items-center">
            <VButton
              class="btn-primary-soft inline-block mr-2"
              :label="$t('app.export_results')"
              @click="$emit('click:export')"
            />

            <VButton
              v-if="selectedSavedSearch"
              class="btn-outline-primary inline-block mr-2"
              :label="$t('app.update_criteria')"
              @click="onClickUpdateSavedSearch"
            />

            <VButton
              v-else
              class="btn-outline-primary inline-block mr-2"
              :label="$t('app.save_criteria')"
              @click="onClickCreateSavedSearch"
            />

            <VSelect
              v-if="!isSavedSearchBeingDeleted"
              v-model="selectedSavedSearch"
              :options="savedSearches"
              class="w-96 flex mr-2"
              :placeholder="$t('app.saved_searches')"
              @update:model-value="onSelectSavedSearch"
            />

            <VDeleteAction
              v-if="selectedSavedSearch"
              :item="selectedSavedSearch"
              :text-value="getSavedSearch()?.name ?? ''"
              :delete-function="onClickDeleteSavedSearch"
            />
          </div>

          <VButton
            class="btn-primary"
            :label="$t('app.search')"
            :is-loading="isSearching"
            @click="onClickSearch"
          />
        </div>
      </template>
    </VCard>

    <FormModal
      v-if="isModalVisible"
      id="saved-search-modal"
      :is-loading="isSaving"
      @click:cancel="hideModal"
      @click:confirm="onClickConfirm(criteria)"
    >
      <template #header>
        {{
          selectedSavedSearch
            ? $t("app.update_saved_search")
            : $t("app.add_saved_search")
        }}
      </template>

      <template #content>
        <VText
          v-model="v.name.$model"
          :placeholder="$t('app.name')"
          :errors="v.name.$errors"
          :disabled="selectedSavedSearch != ''"
          @keyup.enter="onClickConfirm(criteria)"
        />
      </template>
    </FormModal>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { inject, onMounted, computed, watch } from "vue";
import { useStore } from "@/store";
// Composables
import useSavedSearches from "@/composables/useSavedSearches";
import usePinnedSearches from "@/composables/usePinnedSearches";
// Components
import VButton from "@/components/VButton";
import VCard from "@/components/VCard";
import VAction from "@/components/tables/VAction";
import FormModal from "@/components/modals/FormModal";
import VText from "@/components/inputs/VText.vue";
import VSelect from "@/components/inputs/VSelect.vue";
import VDeleteAction from "@/components/VDeleteAction";

export default {
  components: {
    VDeleteAction,
    VButton,
    VCard,
    FormModal,
    VAction,
    VText,
    VSelect
  },
  props: {
    // @TODO: use provide/inject instead
    fieldOptions: {
      type: Array,
      default: () => []
    },
    // @TODO: use provide/inject instead
    isSearching: {
      type: Boolean,
      default: false
    }
  },
  emits: ["click:search", "click:export"],
  setup(props, { emit }) {
    // MISC
    const { t } = useI18n();
    const store = useStore();
    const {
      pinnedSearches,
      onClickPin,
      onClickLock,
      getPinnedSearches,
      deletePinnedSearch
    } = usePinnedSearches();
    const types = inject("types");

    // CONSTANTS
    const typeOptions = [
      "EQUAL",
      "LESSER_EQUAL",
      "GREATER_EQUAL",
      "GREATER",
      "LESSER",
      "NOT_EQUAL",
      "LIKE",
      "RANGE"
    ].map(c => ({
      text: t(`app.${c.toLowerCase()}`),
      value: c
    }));

    // COMPUTED
    const criteria = computed(() => store.getters["criteria/criteria"]);
    const filters = computed(() => {
      return criteria.value
        .filter(({ f, c, v }) => f && c && v)
        .map(({ f, c, v }) => ({
          f,
          c,
          v: Array.isArray(v) ? v : [v]
        }));
    });

    // METHODS
    /**
     * Checks if field is select
     * @param {String} field - name of the field
     * @returns {Boolean} true if equal to select, false otherwise
     */
    const isSelect = field => types[field] === "select";

    const onClickAddCriterion = () => {
      addCriterion();
    };

    const addCriterion = () => {
      const value =
        props.fieldOptions.length > 0 ? props.fieldOptions[0].value : "";

      store.dispatch("criteria/addCriterion", value);

      if (isSelect(value)) {
        store.dispatch("criteria/setCriterion", {
          index: 0,
          value: {
            c: "EQUAL",
            comparatorReadonly: true
          }
        });
      }
    };

    const onClickRemoveCriterion = async index => {
      const criterion = criteria.value[index];

      if (criterion.isLocked || criterion.isPinned) {
        await deletePinnedSearch(criterion.id, index);
      }

      store.dispatch("criteria/removeCriterion", index);

      if (criteria.value.length === 0) {
        addCriterion();
      }
    };

    const onClickSearch = () => {
      emit("click:search", filters.value);
    };

    const setDefaultCriterias = () => {
      store.dispatch("criteria/setCriteria", pinnedSearches.value);

      if (criteria.value.length === 0) {
        addCriterion();
      }
    };

    const onUpdateCriterionField = (value, index) => {
      if (["select", "color", "switch"].includes(types[value])) {
        store.dispatch("criteria/setCriterion", {
          value: {
            f: value,
            c: "EQUAL",
            comparatorReadonly: true
          },
          index
        });

        return;
      } else {
        store.dispatch("criteria/setCriterion", {
          value: {
            f: value,
            comparatorReadonly: false
          },
          index
        });
      }

      store.dispatch("criteria/setCriterionField", {
        value,
        index
      });
    };

    const onUpdateCriterionComparator = (value, index) => {
      store.dispatch("criteria/setCriterionComparator", {
        value,
        index
      });
    };

    // WATCH
    watch(pinnedSearches, () => {
      if (!selectedSavedSearch.value) {
        setDefaultCriterias();
      }
    });

    // LIFECYCLE HOOKS
    onMounted(async () => {
      await getPinnedSearches();

      if (criteria.value.length === 0) {
        setDefaultCriterias();
      }

      await getSavedSearches();
    });

    // CUSTOM COMPOSABLES
    const {
      isModalVisible,
      hideModal,
      isSaving,
      selectedSavedSearch,
      savedSearches,
      v,
      isSavedSearchBeingDeleted,
      getSavedSearches,
      onSelectSavedSearch,
      onClickConfirm,
      onClickCreateSavedSearch,
      onClickUpdateSavedSearch,
      onClickDeleteSavedSearch,
      getSavedSearch
    } = useSavedSearches(setDefaultCriterias);

    return {
      filters,
      onClickAddCriterion,
      onClickRemoveCriterion,
      criteria,
      onClickSearch,
      typeOptions,
      onUpdateCriterionField,
      onUpdateCriterionComparator,
      // usePinnedSearches
      onClickLock,
      onClickPin,
      // useSavedSearches
      isModalVisible,
      isSaving,
      hideModal,
      onClickConfirm,
      v,
      isSavedSearchBeingDeleted,
      savedSearches,
      selectedSavedSearch,
      onSelectSavedSearch,
      onClickCreateSavedSearch,
      onClickUpdateSavedSearch,
      onClickDeleteSavedSearch,
      getSavedSearch
    };
  }
};
</script>
