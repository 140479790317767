import { ref, inject } from "vue";
import { useStore } from "@/store";
import { required } from "@/i18n/i18n-validators";
import useVuelidate from "@vuelidate/core";
import useModal from "@/composables/useModal";

export default function useSavedSearches(setDefaultCriterias) {
  // MISC
  // eslint-disable-next-line
  const { isModalVisible, showModal, hideModal } = useModal(
    "#saved-search-modal"
  );
  const view_code = inject("view_code");
  const toast = inject("toast");
  const store = useStore();

  // DATA
  const isSaving = ref(false);
  const selectedSavedSearch = ref("");
  const savedSearches = ref([]);
  const name = ref(null);
  const v = useVuelidate({ name: { required } }, { name });
  const isSavedSearchBeingDeleted = ref(false);

  // METHODS
  /**
   * Triggers on user interaction.
   * Creates or updates a saved search.
   * @param {Object} criteria
   */
  const onClickConfirm = async criteria => {
    const isValid = await v.value.$validate();

    if (!isValid) {
      return;
    }

    isSaving.value = true;

    const savedSearchCriteria = criteria
      .filter(({ f, c, v }) => f && c && v)
      .map(criterion => ({
        field: criterion.f,
        comparator: criterion.c,
        value: criterion.v
      }));

    if (savedSearchCriteria.length === 0) {
      hideModal();
      isSaving.value = false;
      return;
    }

    if (selectedSavedSearch.value) {
      savedSearchCriteria.unshift({
        saved_search_id: selectedSavedSearch.value
      });
    }

    const response = await store.dispatch("api/request", {
      endpoint: `my.saved-searches.${
        selectedSavedSearch.value ? "update" : "create"
      }`,
      pathParams: {
        id: selectedSavedSearch.value
      },
      data: selectedSavedSearch.value
        ? {
            criteria: JSON.stringify([savedSearchCriteria])
          }
        : {
            view_code,
            name: name.value,
            criteria: JSON.stringify(savedSearchCriteria)
          },
      toast
    });

    if (response && response?.payload?.message?.status === "Success") {
      hideModal();
      await getSavedSearches();
    }

    isSaving.value = false;
  };

  /**
   * Gets all saved searches
   */
  const getSavedSearches = async () => {
    const response = await store.dispatch("api/request", {
      endpoint: "my.saved-searches",
      queryParams: {
        ro_f: encodeURIComponent(
          JSON.stringify([
            {
              f: "view_code",
              c: "EQUAL",
              v: [view_code]
            }
          ])
        )
      }
    });

    const data = response?.payload?.data || [];
    savedSearches.value = data.map(x => ({
      ...x,
      text: x.name,
      value: x.id
    }));
  };

  /**
   * Triggers on user interaction.
   * If user selects a saved search, sets the saved search criteria.
   * If user unselect a saved search, sets the default criteria.
   * @param {String|Number} id - the saved search id
   */
  const onSelectSavedSearch = async id => {
    if (id) {
      await setSavedSearchCriterias(id);
      return;
    }

    setDefaultCriterias();
  };

  /**
   * Sets the saved search criteria
   * @param {String|Number} id - the saved search id
   */
  const setSavedSearchCriterias = async id => {
    const response = await store.dispatch("api/request", {
      endpoint: "my.saved-searches.read",
      pathParams: { id },
      queryParams: {
        ro_r: ["criteria"]
      }
    });

    const data = response?.payload?.data[0]?.criteria || [];
    const value = data.map(x => ({
      f: x.field,
      c: x.comparator,
      v: x.value
    }));
    store.dispatch("criteria/setCriteria", value);
  };

  /**
   * Triggers on user interaction.
   * Open a modal to enter the name of the saved search to create.
   */
  const onClickCreateSavedSearch = () => {
    name.value = "";
    showModal();
  };

  /**
   * Triggers on user interaction.
   * Open a modal to enter the name of the saved search to update.
   */
  const onClickUpdateSavedSearch = () => {
    const savedSearch = getSavedSearch();
    name.value = savedSearch?.name || "";
    showModal();
  };

  /**
   * Gets the value of the selected saved search.
   * @returns saved search
   */
  const getSavedSearch = () => {
    return savedSearches.value.find(ss => ss.id == selectedSavedSearch.value);
  };

  /**
   * Triggers on user interaction.
   * Deletes selected saved search.
   */
  const onClickDeleteSavedSearch = async () => {
    isSavedSearchBeingDeleted.value = true;

    await store.dispatch("api/request", {
      endpoint: "my.saved-searches.delete",
      pathParams: {
        id: selectedSavedSearch.value
      },
      toast
    });

    selectedSavedSearch.value = "";
    setDefaultCriterias();
    await getSavedSearches();

    isSavedSearchBeingDeleted.value = false;
  };

  return {
    isModalVisible,
    showModal,
    hideModal,
    isSaving,
    selectedSavedSearch,
    savedSearches,
    v,
    isSavedSearchBeingDeleted,
    getSavedSearches,
    onSelectSavedSearch,
    onClickConfirm,
    onClickCreateSavedSearch,
    onClickUpdateSavedSearch,
    onClickDeleteSavedSearch,
    getSavedSearch
  };
}
