import { computed, watch } from "vue";
import { useStore } from "@/store";
// Constants
import inputTypes from "@/constants/inputTypes";

export default function useFilterForm(index, emit, types) {
  // MISC
  const store = useStore();

  // COMPUTED
  const criterion = computed(() =>
    store.getters["criteria/getCriterion"](index)
  );
  const field = computed(() => criterion?.value?.f);
  const value = computed(() => criterion?.value?.v);
  const readonly = computed(() => criterion?.value?.valueReadonly);

  // METHODS
  const onUpdateCriterionValue = v => {
    store.dispatch("criteria/setCriterionValue", {
      value: v,
      index
    });
  };

  const onKeyupEnter = () => {
    emit("keyup:enter");
  };

  const setDefaultCriterionValue = field => {
    if (isColor(field)) {
      onUpdateCriterionValue("");
    }
  };

  watch(field, () => {
    setDefaultCriterionValue(field.value);
  });

  const isText = field => isType(field, inputTypes.TEXT);
  const isNumber = field => isType(field, inputTypes.NUMBER);
  const isSelect = field => isType(field, inputTypes.SELECT);
  const isColor = field => isType(field, inputTypes.COLOR);
  const isDate = field => isType(field, inputTypes.DATE);
  const isTags = field => isType(field, inputTypes.TAGS);
  const isType = (field, type) => types[field] === type;

  return {
    isText,
    isNumber,
    isSelect,
    isColor,
    isDate,
    isTags,
    field,
    value,
    readonly,
    onUpdateCriterionValue,
    onKeyupEnter
  };
}
