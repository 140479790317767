import { ref, inject } from "vue";
import { useStore } from "@/store";

export default function usePinnedSearches() {
  // MISC
  const store = useStore();
  const view_code = inject("view_code");
  const types = inject("types");

  // DATA
  const pinnedSearches = ref([]);

  // METHODS
  /**
   * Triggers on user interaction.
   * Will pin, which means it will either delete or create a pinned search if it exists or not.
   * @param {Object} criterion - the pinned search criterion
   * @param {Number} index - the pinned search index
   */
  const onClickPin = async (criterion, index) => {
    const { f, id } = criterion;

    setLoading("isPinning", true, index);

    if (id) {
      await deletePinnedSearch(id, index);
    } else {
      if (!f) {
        return;
      }

      await updatePinnedSearch(null, { field: f }, index, criterion, "create");
    }

    setLoading("isPinning", false, index);
  };

  /**
   * Deletes a pinned search
   * @param {Number|String} id the pinned search id
   * @param {Number} index - the pinned search index
   */
  const deletePinnedSearch = async (id, index) => {
    await store.dispatch("api/request", {
      endpoint: "my.pinned-searches.delete",
      pathParams: {
        id
      }
    });

    updateDeletedPinnedSearchCriterion(index);
  };

  /**
   * Updates the deleted pinned search criterion
   * @param {Number} index - the pinned search index
   */
  const updateDeletedPinnedSearchCriterion = index => {
    store.dispatch("criteria/setCriterion", {
      index,
      value: {
        id: null,
        isLocked: false,
        isPinned: false,
        valueReadonly: false,
        fieldReadonly: false,
        comparatorReadonly: false
      }
    });
  };

  /**
   * Updates the (created or updated) pinned search criterion
   * @param {Object} data - the data that needs to be updated on the criterion
   * @param {Number} index - the pinned search index
   * @param {Object} criterion - the criterion data
   */
  const updatePinnedSearchCriterion = (data, index, criterion) => {
    if (!data) {
      return;
    }

    const isPinned = !!data.field;
    const isLocked = !!data.value;
    const v = isSelect(data.field) ? JSON.parse(data.value) : data.value;

    store.dispatch("criteria/setCriterion", {
      index,
      value: {
        id: data.id,
        isLocked,
        isPinned,
        valueReadonly: isLocked,
        fieldReadonly: isPinned,
        comparatorReadonly: isLocked,
        f: data.field,
        c: data.comparator || criterion.c,
        v: v || criterion.v
      }
    });
  };

  /**
   * Checks if field is select
   * @param {String} field - name of the field
   * @returns {Boolean} true if equal to select, false otherwise
   */
  const isSelect = field => types[field] === "select";

  /**
   * Creates or updates a pinned search
   * @param {Number|String} id - the pinned search id
   * @param {Object} data - the pinned search data
   * @param {String} index - the pinned search index
   * @param {Object} value - the criterion data
   * @param {Object} action - the action that needs to be performed - can be either create or update
   */
  const updatePinnedSearch = async (id, data, index, criterion, action) => {
    let dataValue = data;

    if (dataValue.value) {
      dataValue = {
        ...dataValue,
        value: Array.isArray(dataValue.value)
          ? JSON.stringify(dataValue.value)
          : dataValue.value
      };
    }

    const response = await store.dispatch("api/request", {
      endpoint: `my.pinned-searches.${action}`,
      pathParams: {
        id
      },
      data: {
        ...dataValue,
        view_code
      }
    });

    updatePinnedSearchCriterion(response?.payload?.data, index, criterion);
  };

  /**
   * Gets all pinned searches for a specific view
   */
  const getPinnedSearches = async () => {
    const response = await store.dispatch("api/request", {
      endpoint: "my.pinned-searches",
      queryParams: {
        ro_f: encodeURIComponent(
          JSON.stringify([
            {
              f: "view_code",
              c: "EQUAL",
              v: [view_code]
            }
          ])
        )
      }
    });

    const data = response?.payload?.data || [];

    pinnedSearches.value = data.map(pinnedSearch => {
      const isLocked = !!pinnedSearch.value;
      const v = isSelect(pinnedSearch.field)
        ? JSON.parse(pinnedSearch.value)
        : pinnedSearch.value;

      return {
        id: pinnedSearch.id,
        f: pinnedSearch.field,
        fieldReadonly: true,
        c: pinnedSearch.comparator,
        comparatorReadonly: isLocked,
        v,
        valueReadonly: isLocked,
        isPinned: true,
        isLocked
      };
    });
  };

  /**
   * Triggers on user interaction.
   * Will lock, which means it will either update or create a pinned search if it exists or not.
   * @param {Object} criterion - the pinned search criterion
   * @param {Number} index - the pinned search index
   */
  const onClickLock = async (criterion, index) => {
    const { f, c, v, id, isLocked } = criterion;

    setLoading("isLocking", true, index);

    if (id) {
      await updatePinnedSearch(
        id,
        {
          value: isLocked ? "" : v,
          comparator: c
        },
        index,
        criterion,
        "update"
      );
    } else {
      await updatePinnedSearch(
        null,
        {
          field: f,
          comparator: c,
          value: v
        },
        index,
        criterion,
        "create"
      );
    }

    setLoading("isLocking", false, index);
  };

  /**
   * Sets a loading icon for the pinned search.
   * Expects the valueProperty to be either isPinning or isLocking
   * @param {String} valueProperty - the pinned search key
   * @param {Boolean} value - the pinned search value
   * @param {Number} index - the pinned search index
   */
  const setLoading = (valueProperty, value, index) => {
    store.dispatch("criteria/setCriterion", {
      index,
      value: {
        [valueProperty]: value
      }
    });
  };

  return {
    onClickPin,
    onClickLock,
    getPinnedSearches,
    deletePinnedSearch,
    pinnedSearches
  };
}
